import {
  DataTypeProvider,
  FilteringState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from "@devexpress/dx-react-grid"
import {
  DragDropProvider,
  Grid,
  GroupingPanel,
  Table,
  TableFilterRow,
  TableGroupRow,
  TableHeaderRow,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui"
import Paper from "@material-ui/core/Paper"
import { Divider } from "antd"
import { groupBy, map, sortBy } from "lodash"
import React from "react"
import { useCollection } from "react-firebase-hooks/firestore"
import { Certificate } from "../../../functions/database/interfaces"
import { Logout } from "../../components/logout"
import { certificatesRef, coursesRef } from "../../config/collectionsRef"

export interface CertificateWithId {
  snapshot: Certificate
  id: string
}

// https://us-central1-mastery-academy-f8223.cloudfunctions.net/sendToCertificatesNewStatusToAgents

const CertificateApprovedTable = () => {
  const [certificateSnapshot, loading] = useCollection(
    certificatesRef.where("status", "==", "pendingApproval")
  )
  const [coursesSnapshot, loadingUsers] = useCollection(coursesRef)

  const dataSource =
    certificateSnapshot === undefined
      ? []
      : certificateSnapshot.docs.map((s) => {
          return {
            ...(s.data() as Certificate),
            id: s.id,
            generatedAt: s.data()?.generatedAt?.toDate().toLocaleString(),
            courseName:
              coursesSnapshot?.docs
                .find((e) => e.id == s.data().courseId)
                ?.data().name ?? "",
            ref: s.ref,
          }
        })

  const approveAll = async () => {
    const r = window.confirm(
      "Are you sure you wont to Approve All Certificates?"
    )
    if (r == true) {
      let requestData = {}
      const studentsList = dataSource.map((x) => {
        return {
          agentId: x.agentId,
          studentName: x.studentName,
        }
      })
      const groupedNames = groupBy(studentsList, (x) => x.agentId)

      await Promise.all(
        map(groupedNames, (values, x) => {
          requestData = {
            ...requestData,
            [x]: {
              approved: values.map((x) => x.studentName),
              rejected: [],
            },
          }
        })
      )

      await fetch(
        "https://us-central1-mastery-academy-f8223.cloudfunctions.net/sendToCertificatesNewStatusToAgents",
        {
          method: "POST",
          body: JSON.stringify({
            reqData: requestData,
          }),
        }
      )

      dataSource.map((x) => x.ref.update({ status: "approved" }))
    }
  }

  const rejectAll = async () => {
    const r = window.confirm(
      "Are you sure you wont to Rejecte All Certificates?"
    )
    if (r == true) {
      let requestData = {}
      const studentsList = dataSource.map((x) => {
        return {
          agentId: x.agentId,
          studentName: x.studentName,
        }
      })
      const groupedNames = groupBy(studentsList, (x) => x.agentId)

      await Promise.all(
        map(groupedNames, (values, x) => {
          requestData = {
            ...requestData,
            [x]: {
              approved: [],
              rejected: values.map((x) => x.studentName),
            },
          }
        })
      )
      dataSource.map((x) => x.ref.update({ status: "rejected" }))
    }
  }

  const columns = [
    {
      name: "courseName",
      title: "Course Name",
    },

    {
      title: "Student Name ",
      name: "studentName",
      key: "studentName",
    },

    {
      title: "Certificate Number ",
      name: "number",
      key: "number",
    },

    { name: "generatedAt", title: "Created Date" },

    {
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <>
            <span
              style={{ color: "#007bff", cursor: "pointer" }}
              onClick={async () => {
                const requestData = {
                  [row.agentId]: {
                    approved: [row.studentName],
                    rejected: [],
                  },
                }
                await fetch(
                  "https://us-central1-mastery-academy-f8223.cloudfunctions.net/sendToCertificatesNewStatusToAgents",
                  {
                    method: "POST",
                    body: JSON.stringify({
                      reqData: requestData,
                    }),
                  }
                )

                certificatesRef.doc(row.id).update({
                  status: "approved",
                } as Certificate)
              }}
            >
              Approve
            </span>

            <Divider type="vertical" />

            <span
              style={{ color: "#9e000f", cursor: "pointer" }}
              onClick={async () => {
                const requestData = {
                  [row.agentId]: {
                    approved: [],
                    rejected: [row.studentName],
                  },
                }
                await fetch(
                  "https://us-central1-mastery-academy-f8223.cloudfunctions.net/sendToCertificatesNewStatusToAgents",
                  {
                    method: "POST",
                    body: JSON.stringify({
                      reqData: requestData,
                    }),
                  }
                )

                certificatesRef.doc(row.id).set({
                  status: "rejected",
                } as Certificate)
              }}
            >
              Rejected
            </span>
          </>
        )
      },
    },
  ]

  return (
    <div>
      <section style={{ marginTop: 200 }}>
        <div className="container-fluid">
          <div className="row pl-50 pr-50">
            <Paper>
              <div className="buttons-container">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    approveAll()
                  }}
                >
                  Approve All
                </button>

                <button
                  className="btn btn-primary"
                  onClick={() => {
                    rejectAll()
                  }}
                  style={{ background: "red", margin: "0px 8px" }}
                >
                  Reject All
                </button>
              </div>

              <Grid
                rows={sortBy(dataSource, (x) => x.generatedAt).reverse()}
                columns={columns}
                getRowId={(r) => r.id}
              >
                <DragDropProvider />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />
                <GroupingState defaultGrouping={[]} />
                <IntegratedGrouping />
                <PagingState
                  defaultCurrentPage={0}
                  pageSize={dataSource.length}
                />
                <IntegratedPaging />

                <DataTypeProvider
                  for={["courseName", "id"]}
                  availableFilterOperations={[
                    "endsWith",
                    "startsWith",
                    "contains",
                  ]}
                />

                <FilteringState defaultFilters={[]} />
                <IntegratedFiltering />

                <Table />

                <TableFilterRow showFilterSelector />

                {/* <PagingPanel /> */}
                <TableHeaderRow showSortingControls />
                <TableGroupRow />
                <Toolbar />

                <GroupingPanel />
                {/* <TableEditRow /> */}

                {/* <TableEditColumn /> */}
              </Grid>
            </Paper>
          </div>
        </div>
      </section>

      <Logout />
    </div>
  )
}

export default CertificateApprovedTable
